import { useCallback, useEffect, useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { IGooglePlaceDetails } from '../types/graphql'

const NEAR_USER = 'NEAR_USER'

const useUserLocation = (
  dontWaitForConsent = false
): {
  placeDetails: IGooglePlaceDetails | undefined
  loading: boolean
  getUserLocation: () => Promise<IGooglePlaceDetails | undefined>
} => {
  const [consent, setConsent] = useState<boolean>(dontWaitForConsent ?? false)
  const [placeDetails, setPlaceDetails] = useState<IGooglePlaceDetails>()
  const [loadingPosition, setLoadingPosition] = useState<boolean>(
    true && dontWaitForConsent
  )
  const logger = useLogger()

  useEffect(() => {
    if (!window.navigator.permissions) return
    const checkPermission = async () => {
      const permission = await navigator.permissions.query({
        name: 'geolocation'
      })
      if (permission.state === 'granted') {
        setConsent(true)
      }
    }
    checkPermission()
  }, [])

  /* Near User on Load */
  useEffect(() => {
    if (!consent) return

    if (!window.navigator.geolocation) {
      setPlaceDetails(undefined)
      setLoadingPosition(false)
      return
    }

    // Fallback
    let hasBeenSet = false
    const handler = setTimeout(() => {
      if (!hasBeenSet) {
        hasBeenSet = true
        setPlaceDetails(undefined)
        setLoadingPosition(false)
      }
    }, 10000)

    setLoadingPosition(true)

    window.navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        hasBeenSet = true
        setPlaceDetails({ lat: latitude, lng: longitude, placeId: NEAR_USER })
        setLoadingPosition(false)
      },
      (error) => {
        logger.error('Error retrieving geolocation', error)
        if (!hasBeenSet) {
          hasBeenSet = true
          setPlaceDetails(undefined)
          setLoadingPosition(false)
        }
      }
    )

    return () => {
      clearTimeout(handler)
    }
  }, [logger, consent])

  /* Near User on Action  */
  const getUserLocation = useCallback(async () => {
    if (placeDetails) return placeDetails

    const placeD = await getGeolocation()
    if (placeD) {
      setPlaceDetails(placeD)
    }
    return placeD
  }, [placeDetails])

  return { placeDetails, loading: loadingPosition, getUserLocation }
}

function getGeolocation(): Promise<IGooglePlaceDetails | undefined> {
  return new Promise((resolve) => {
    if (!window.navigator.geolocation) return resolve(undefined)

    window.navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        resolve({ lat: latitude, lng: longitude, placeId: NEAR_USER })
      },
      () => {
        resolve(undefined)
      }
    )
  })
}

export default useUserLocation
